import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import TuneTwoToneIcon from "@mui/icons-material/TuneTwoTone";
import { Drawer, Pagination, PaginationItem, Typography } from "@mui/material";
import cn from "classnames";
import Link from "next/link";
import { ImgBgResponsive, Tag } from "@/src/component/base";
import { PageLayoutContext } from "@/src/component/layout/PageLayout";
import { FitlerPanel, ListingGrid, Map, resultsToCards } from "@/src/component/partial";
import { useApp, useCMS } from "@/src/component/provider";
import { ENV, FILTERS } from "@/src/const";
import { DEFAULT_LOCATION } from "@/src/const/map";
import { SearchFiltersType, useBreakPointDown, useNextNavigation } from "@/src/hook";
import { SearchLogic } from "@/src/model";
import { BlaceV2API } from "@/src/service";
import { AppSearchFilterType, BlaceV2Type, ListingDisplayVariant } from "@/src/type";
import { ImageFromCDN, Log, URLHelper } from "@/src/util";
import type { MapLocationMarker } from "@/src/component/partial";
import type { AppContextType } from "@/src/component/provider";
import styles from "./Listing.module.scss";
import { DataTypeButtonGroup, MapButton, NoResults, QuickFilters } from "./component";

interface ListingProps {
  initialSearch: BlaceV2Type.AzureSearchQueryType.Response<BlaceV2Type.SearchType.SearchItem>;
  initialGeoPoints: BlaceV2Type.AzureSearchQueryType.Response<BlaceV2Type.SearchType.SearchItem>;
  initialQuery: BlaceV2Type.AzureSearchQueryType.Request;
  initialPerPage: number;
  initialPage: number;
}

type LastSearchQuery = {
  searchId?: string;
  searchTerm?: string;
  resultsPerPage: number;
  pageNumber: number;
  searchResults: BlaceV2Type.AzureSearchQueryType.Response<BlaceV2Type.SearchType.SearchItem>;
  query: BlaceV2Type.AzureSearchQueryType.Request;
  geoPoints: BlaceV2Type.AzureSearchQueryType.Response<BlaceV2Type.SearchType.SearchItem>;
  cards: ReactElement[];
  viewVariant: keyof typeof ListingDisplayVariant;
};

interface FilterTag {
  isMulti: boolean;
  title: string;
  data: string;
  dataKey: string;
  value: string;
  defaultValue?: number | string;
}

function Listing({
  initialSearch,
  initialGeoPoints,
  initialQuery,
  initialPerPage,
  initialPage,
}: ListingProps) {
  const isMobile = useBreakPointDown("md");
  const isProd = ENV === "prod";
  const app = useApp();
  const cms = useCMS();
  const defaultListingType = BlaceV2Type.SearchTypes.Venue;
  const nextNavigation = useNextNavigation();
  const [viewVariant, setViewVariant] = useState<keyof typeof ListingDisplayVariant>(
    ListingDisplayVariant.full,
  );
  const [showMap, setShowMap] = useState<boolean>(false);
  const [listingType, setListingType] = useState(
    app.searchFilters?.filterState.dataType
      ? app.searchFilters?.filterState.dataType[0]
      : defaultListingType,
  );
  const [mapDrawerOpen, setMapDrawerOpen] = useState<boolean>(false);
  const [hoveredListingSlug, setHoveredListingSlug] = useState<string | undefined>(undefined);
  const [lastSearchQuery, setLastSearchQuery] = useState<LastSearchQuery>({
    searchId: app.searchFilters?.searchId,
    searchTerm: app.searchTerm,
    resultsPerPage: initialPerPage ?? 20,
    pageNumber: initialPage,
    searchResults: initialSearch,
    query: initialQuery,
    geoPoints: initialGeoPoints,
    viewVariant: viewVariant ?? ListingDisplayVariant.full,
    cards: resultsToCards({
      items: initialSearch.value,
      viewVariant,
      ads:
        (1 * 5) % 10 === 0
          ? [{ placement: 4 }, { placement: 11 }]
          : [{ placement: 6 }, { placement: 14 }],
      alwaysEager: false,
      onCardHover: (listingSlug?: string) => setHoveredListingSlug(listingSlug),
    }),
  });

  // //last city background
  const [backgroundImageCity, setBackgroundImageCity] = useState<string | undefined>(
    app?.searchFilters?.filterState?.regions ? app?.searchFilters?.filterState?.regions : undefined,
  );

  // const isCitySelected = !!backgroundImageCity;
  const pageLayoutContext = PageLayoutContext.usePageLayoutContext();

  useEffect(() => {
    // check if filtersState has dataType value
    const newListingType =
      app.searchFilters?.filterState.dataType && app.searchFilters?.filterState.dataType[0];
    // set the listingType to set tabs value and detect is map visible
    setListingType(newListingType || defaultListingType);

    if (!isProd) {
      // temporary condition to disable showing map by default for prod env
      setViewVariant(
        !isMobile && newListingType !== BlaceV2Type.SearchTypes.Vendor
          ? ListingDisplayVariant.map
          : ListingDisplayVariant.full,
      );
    }

    if (newListingType) {
      setShowMap(newListingType !== BlaceV2Type.SearchTypes.Vendor);
    } else {
      setShowMap(true);
    }
  }, [app.searchFilters?.filterState.dataType, defaultListingType, isMobile, isProd]);

  useEffect(() => {
    // if listing type was changes - get new data. Needed to set "venue" as a default value when user clicks on logo
    const params = new URLSearchParams(nextNavigation.searchParams.toString());
    runSearchQuery(lastSearchQuery, app, parseInt(params.get("page") ?? "1", 10));
    setShowMap(listingType === BlaceV2Type.SearchTypes.Venue);

    if (!isProd) {
      // temporary condition to disable showing map by default for prod env
      setViewVariant(
        !isMobile && listingType !== BlaceV2Type.SearchTypes.Vendor
          ? ListingDisplayVariant.map
          : ListingDisplayVariant.full,
      );
    }
  }, [listingType, isMobile, isProd, app.searchFilters?.filterState]);

  /**
   * insert default filter values on the first render
   */
  const filters = app?.searchFilters;
  useEffect(() => {
    insertDefaultFilters(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * clear the background if the route changes
   */
  useEffect(() => {
    if (backgroundImageCity === app?.searchFilters?.filterState?.regions) {
      return;
    }

    if (!backgroundImageCity && !app?.searchFilters?.filterState?.regions) {
      return;
    }

    setBackgroundImageCity(app?.searchFilters?.filterState?.regions);
  }, [app?.searchFilters?.filterState?.regions, backgroundImageCity]);

  /**
   * load query parameters on initialization
   */
  useEffect(() => {
    const paramViewVariant = nextNavigation.getAndValidateInitialQueryParam(
      ListingDisplayVariant,
      "viewVariant",
    );
    if (paramViewVariant) {
      setViewVariant(paramViewVariant);
    }

    return () => {
      pageLayoutContext.setShowListingQuickFiltersInHeader(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    pageLayoutContext.setShowListingQuickFiltersInHeader(isMobile);
  }, [isMobile]);

  /**
   * effects to control the map drawer when in mobile mode
   */
  useEffect(() => {
    if (!isMobile && mapDrawerOpen) {
      setMapDrawerOpen(false);
      return;
    }

    if (viewVariant === ListingDisplayVariant.map && !mapDrawerOpen && isMobile) {
      setMapDrawerOpen(true);
    }

    if (viewVariant === ListingDisplayVariant.full && mapDrawerOpen && isMobile) {
      setMapDrawerOpen(false);
    }
  }, [isMobile, viewVariant, mapDrawerOpen]);

  /**
   * turn off map drawer if search focus
   */
  useEffect(() => {
    if (app.searchFocus && mapDrawerOpen) {
      setViewVariant(ListingDisplayVariant.full);
    }
  }, [app.searchFocus, mapDrawerOpen]);

  /**
   * run a new search when the page number changes
   * app.searchTerm and app.searchFilters will both change the page number
   */
  useEffect(() => {
    const params = new URLSearchParams(nextNavigation.searchParams.toString());

    if (viewVariant !== lastSearchQuery.viewVariant) {
      setLastSearchQuery({
        ...lastSearchQuery,
        ...{
          viewVariant,
          cards: resultsToCards({
            items: lastSearchQuery.searchResults.value,
            viewVariant,
            ads:
              (lastSearchQuery.pageNumber * 5) % 10 === 0
                ? [{ placement: 4 }, { placement: 11 }]
                : [{ placement: 6 }, { placement: 14 }],
            alwaysEager: true,
            onCardHover: (listingSlug?: string) => setHoveredListingSlug(listingSlug),
          }),
        },
      });
      return;
    }

    if (
      parseInt(params.get("page") ?? "1", 10) === lastSearchQuery.pageNumber &&
      ((app.searchTerm ?? "|none|") === (lastSearchQuery.searchTerm ?? "|none|") ||
        (app.searchTerm === "" && (lastSearchQuery.searchTerm ?? "") === "")) &&
      app.searchFilters?.searchId === lastSearchQuery.searchId
    ) {
      return;
    }

    Log.logToConsoleDebug("Listing.tsx", "Search varaibles triggered query", [
      {
        pageNumber: parseInt(params.get("page") ?? "1", 10) === lastSearchQuery.pageNumber,
        searchTerm:
          (app.searchTerm ?? "|none|") === (lastSearchQuery.searchTerm ?? "|none|") ||
          (app.searchTerm === "" && (lastSearchQuery.searchTerm ?? "") === ""),
        filters: app.searchFilters?.searchId === lastSearchQuery.searchId,
      },
      {
        oldPageNumber: lastSearchQuery.pageNumber,
        currentPageNumber: parseInt(params.get("page") ?? "1", 10),
        oldSearchId: lastSearchQuery.searchId,
        currentSearchId: app.searchFilters?.searchId,
        oldSearchTerm: lastSearchQuery.searchTerm ?? "|none|",
        currentSearchTerm: app.searchTerm ?? "|none|",
      },
    ]);

    runSearchQuery(lastSearchQuery, app, parseInt(params.get("page") ?? "1", 10));
  }, [
    app.searchTerm,
    app?.searchFilters?.filterState,
    nextNavigation.searchParams,
    lastSearchQuery,
    viewVariant,
  ]);

  /**
   * results grid
   */
  const filterTags = useMemo(
    () => filterDataToList(app.searchFilters?.filterState),
    [app.searchFilters?.filterState],
  );
  const pages = useMemo(
    () =>
      Math.ceil(
        (lastSearchQuery.searchResults?.["@odata.count"] ?? 0) / lastSearchQuery.resultsPerPage,
      ),
    [lastSearchQuery],
  );

  /**
   * when in debug mode show the search result in console
   */
  useEffect(() => {
    Log.logToConsoleDebug("Listing.tsx", "Search change", [
      { lastSearchQuery, initialGeoPoints, initialQuery, filterTags },
    ]);
  }, [lastSearchQuery, initialGeoPoints, initialQuery, filterTags]);

  /**
   * Run a search query after something changes
   *
   * @param lastSearchQuery
   * @param app
   * @param newPageNumber
   * @param newResultsPerPage
   */
  async function runSearchQuery(
    lastSearchQuery: LastSearchQuery,
    app: AppContextType,
    newPageNumber: number,
  ) {
    const from = newPageNumber ?? 1;
    const queryType = app.searchQueryType ?? ListingDisplayVariant.full;
    const query = SearchLogic.defaultQuery(lastSearchQuery.resultsPerPage);
    const filters = app?.searchFilters;

    query.search = SearchLogic.constructSearchTerm(app.searchQueryType, app.searchTerm);
    query.queryType = queryType;
    query.filter = SearchLogic.constructFilter(filters?.filterState);
    query.sessionId =
      URLHelper.urlGetParameter("searchSessionId") ?? initialQuery.sessionId ?? query.sessionId;

    //get the results for the page number
    const searchResponse = await BlaceV2API.SearchServiceV2.postSearchQuery({
      ...query,
      skip: from * lastSearchQuery.resultsPerPage - lastSearchQuery.resultsPerPage,
    });

    if (searchResponse.status !== 200 || typeof searchResponse.body === "undefined") {
      //TODO: add a toast for generic failures
      return;
    }

    //get the results for geo points without page restrictions
    const geoQuery = JSON.parse(JSON.stringify(query));
    geoQuery.select =
      "title, slug, dataType, locations, facts, categories, images, regions, capacity, price";
    geoQuery.top = 10000;
    //filter the map results to only venues
    geoQuery.filter = `${(geoQuery.filter ?? "").length > 0 ? `${geoQuery.filter} and ` : ""}search.in(dataType,'venue','|')`;
    const geoRespones = await BlaceV2API.SearchServiceV2.postSearchQuery(geoQuery);

    let geoPointsToShow = searchResponse.body.payload;
    if (searchResponse.status == 200 && geoRespones?.body?.payload) {
      geoPointsToShow = geoRespones?.body?.payload;
    }

    setLastSearchQuery({
      searchId: app.searchFilters?.searchId,
      searchTerm: app.searchTerm,
      resultsPerPage: lastSearchQuery.resultsPerPage,
      pageNumber: from,
      searchResults: searchResponse.body.payload,
      query: query,
      geoPoints: geoPointsToShow,
      viewVariant,
      cards: resultsToCards({
        items: searchResponse.body.payload.value,
        viewVariant,
        ads:
          (from * 5) % 10 === 0
            ? [{ placement: 4 }, { placement: 11 }]
            : [{ placement: 6 }, { placement: 14 }],
        alwaysEager: true,
        onCardHover: (listingSlug?: string) => setHoveredListingSlug(listingSlug),
      }),
    });

    window.scrollTo(0, 0);
  }

  function insertDefaultFilters(filters?: SearchFiltersType) {
    const dataTypeFilter =
      filters?.getFilterData<BlaceV2Type.SearchType.SearchDataType[]>("dataType");

    if (!dataTypeFilter) {
      filters?.setFilterData("dataType", [BlaceV2Type.SearchTypes.Venue]);
    }
  }

  /**
   * handle putting the results on map as markers
   *
   * @param {BlaceV2Type.SearchType.SearchItem[]} results - search results
   * @returns {MapLocationMarker[]}
   */
  function resultsToCoordinates(
    results?: BlaceV2Type.SearchType.SearchItem[],
  ): MapLocationMarker[] {
    if (!results || !Array.isArray(results)) {
      return [];
    }

    const markers: MapLocationMarker[] = [];
    for (const result of results) {
      for (const location of result.locations ?? []) {
        if (typeof location.latitude === "number" && typeof location.longitude === "number") {
          markers.push({
            lat: location.latitude,
            lng: location.longitude,
            listingData: result,
            to: cms.constructLink(`/${result.dataType}/${result.slug}`),
            slug: result.slug,
          });
        }
      }
    }
    return markers;
  }

  /**
   * toggle the map on / off
   *
   * @returns {void}
   */
  function handleMapToggle(overrideViewVariant?: keyof typeof ListingDisplayVariant) {
    const toChange =
      overrideViewVariant ??
      (viewVariant === ListingDisplayVariant.map
        ? ListingDisplayVariant.full
        : ListingDisplayVariant.map);
    setViewVariant(toChange);
  }

  /**
   * toggle the map on / off
   *
   * @returns {void}
   */
  //TODO: make the map use a map button to toggle on / off in future
  //TODO: this code is a duplicate of ./component/MapButton.tsx
  function handleMapToggleMap() {
    const toChange =
      viewVariant === ListingDisplayVariant.map
        ? ListingDisplayVariant.full
        : ListingDisplayVariant.map;
    nextNavigation.updateQueryString("viewVariant", toChange);
  }

  /**
   * when the user removes the search revert to the initialQuery and reload
   */
  function handleTermsClear() {
    app.setSearch({});
  }

  /**
   * Translate the filterData state from useSearch for tags in the UI
   *
   * @param {Record<string,any>} filterData  - the filter data state from use search
   * @returns
   */
  function filterDataToList(filterData?: Record<string, any>): FilterTag[] {
    if (!filterData) {
      return [];
    }

    function getLabel(value: string, options?: AppSearchFilterType.AppSearchFilterOption[]) {
      for (const opt of options ?? []) {
        if (opt.value === value) {
          return opt.label;
        }
      }
    }

    const arr: FilterTag[] = [];

    for (const propertyKey of Object.keys(filterData)) {
      const filter = FILTERS[propertyKey];
      if (filter?.dataKey === "dataType") {
        continue;
      }
      if (filter) {
        const propertyData = filterData?.[filter.dataKey];
        if (filter.type === "multi-choice") {
          for (const opt of propertyData) {
            arr.push({
              isMulti: true,
              title: filter.title,
              data: getLabel(opt, filter.options) ?? "",
              dataKey: filter.dataKey,
              value: opt,
            });
          }
        } else if (filter.type === "capacity-slider") {
          const capacityValues = filterData?.[filter.dataKey]?.split("*");
          const isDefaultMin = capacityValues[0] === `${filter.capacitySliderMin}`;
          const isDefaultMax = capacityValues[1] === `${filter.capacitySliderMax}`;
          !isDefaultMin &&
            arr.push({
              isMulti: false,
              title: "min",
              defaultValue: filter.capacitySliderMin,
              data: `${filter.dataKey} from ${capacityValues[0]}`,
              dataKey: filter.dataKey,
              value: propertyData,
            });

          !isDefaultMax &&
            arr.push({
              isMulti: false,
              title: "max",
              defaultValue: filter.capacitySliderMax,
              data: `${filter.dataKey} to ${capacityValues[1]}`,
              dataKey: filter.dataKey,
              value: propertyData,
            });
        } else if (filter.type === "value-ge") {
          // TODO until all filters will be rewritten
          continue;
        } else {
          arr.push({
            isMulti: false,
            title: filter.title,
            data: getLabel(propertyData, filter.options) ?? "",
            dataKey: filter.dataKey,
            value: propertyData,
          });
        }
      }
    }
    return arr;
  }

  function getToLinkForPagination(page: number): string {
    const params = new URLSearchParams(nextNavigation.searchParams.toString());
    params.set("page", `${page}`);
    return `${nextNavigation.pathname}?${params.toString()}`;
  }

  function handleCloseTag(opt: FilterTag) {
    if (opt.dataKey === "capacity") {
      return () => {
        const currentCapacity = opt.value.split("*");
        const withDefaultCapacity =
          opt.title === "min"
            ? `${opt.defaultValue}*${currentCapacity[1]}`
            : `${currentCapacity[0]}*${opt.defaultValue}`;
        app.searchFilters?.setFilterData(opt.dataKey, withDefaultCapacity);
      };
    }
    return opt.isMulti
      ? () => app.searchFilters?.setMultiChoice(opt.dataKey, opt.value)
      : () => app.searchFilters?.setFilterData(opt.dataKey, "");
  }

  const resetFilters = useCallback(() => {
    app.searchFilters!.resetFilterData(listingType);
  }, [listingType]);

  return (
    <>
      <div className={cn(styles.listingHeader, viewVariant)}>
        {false && backgroundImageCity && (
          <div className={cn(styles.listingHeaderBgImageContainer, viewVariant)}>
            <ImgBgResponsive
              className={styles.listingHeaderBgImage}
              imageUrl={ImageFromCDN.imageSizeAndQuality(
                `/${backgroundImageCity}_bg_1.png`,
                80,
                isMobile ? 900 : 1600,
                true,
              )}
              lazy={"eager"}
            >
              <div className={styles.listingHeaderBgImageOverlay} />
            </ImgBgResponsive>
          </div>
        )}
        <div className={cn(styles.listingHeaderInner, viewVariant)}>
          {/* Hide until next sprint */}
          {!isProd && (
            <div className={styles.quickFiltersContainer}>
              <QuickFilters />
            </div>
          )}
          <div
            className={cn(styles.headerButtonsContainer, {
              [styles.withPanelFilters]: isProd,
            })}
          >
            {/* add isCitySelected to show header background */}
            <DataTypeButtonGroup isCitySelected={false} />
            {showMap && (
              <MapButton
                // add isCitySelected to show header background
                isCitySelected={false}
                viewVariant={viewVariant}
                handleMapToggle={handleMapToggle}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={cn(styles.listingContainer, viewVariant, {
          [styles.withPanelFilters]: isProd,
        })}
      >
        {isProd && (
          <div className={cn(styles.listingFilters, viewVariant)}>
            <div className={styles.listFiltersTitle}>
              <TuneTwoToneIcon />
              <Typography variant="body1" fontSize="18px">
                Filters
              </Typography>
            </div>
            <FitlerPanel />
          </div>
        )}
        <div className={cn(styles.listingGrid, viewVariant, { [styles.withPanelFilters]: isProd })}>
          {isProd && (app.searchTerm || (filterTags ?? []).length > 0) && (
            <div className={cn(styles.listingGridTagsRow, viewVariant)}>
              {app.searchTerm && (
                <Tag
                  title={`Search: ${app.searchTerm}`}
                  onClose={handleTermsClear}
                  className={styles.listingGridTagActual}
                />
              )}
              {(filterTags ?? []).map((opt, i) => {
                return (
                  <Tag
                    key={i}
                    title={`${opt.data}`}
                    className={styles.listingGridTagActual}
                    onClose={handleCloseTag(opt)}
                  />
                );
              })}
            </div>
          )}
          {lastSearchQuery.cards.length ? (
            <ListingGrid variant={viewVariant} cards={lastSearchQuery.cards} />
          ) : (
            <NoResults onReset={resetFilters} listingType={listingType} />
          )}
          {pages > 1 && (
            <Pagination
              className={cn(styles.listingPagination, viewVariant)}
              count={pages}
              shape="rounded"
              size="large"
              siblingCount={1}
              boundaryCount={0}
              page={lastSearchQuery.pageNumber ?? 1}
              renderItem={(item) => (
                <Link
                  href={getToLinkForPagination(item.page ?? 1)}
                  className={styles.listingPaginationOption}
                >
                  <PaginationItem {...item} />
                </Link>
              )}
            />
          )}
        </div>
        {showMap && (
          <div
            className={cn(styles.listingMap, viewVariant, { [styles.withPanelFilters]: isProd })}
          >
            <Map
              city={backgroundImageCity}
              visible={true}
              centerMarker={DEFAULT_LOCATION.NYC}
              markers={resultsToCoordinates(
                lastSearchQuery.geoPoints.value ?? lastSearchQuery.searchResults.value ?? [],
              )}
              handleMapToggle={handleMapToggleMap}
              hideMapButton={!isMobile}
              hoveredSlug={hoveredListingSlug}
            />
          </div>
        )}
        <Drawer
          id="Listing.DrawerMap"
          anchor="bottom"
          disableEnforceFocus={true}
          disableAutoFocus={true}
          disableRestoreFocus={true}
          //keep mounted when mobile to prevent map reload
          //this component is not used for desktop
          keepMounted={isMobile}
          onClose={() => setMapDrawerOpen(false)}
          open={isMobile && viewVariant === ListingDisplayVariant.map}
          PaperProps={{
            sx: {
              top: 0,
              width: "100vw",
              bottom: 0,
              "::-webkit-scrollbar": {
                display: "none",
              },
            },
          }}
          slotProps={{
            backdrop: {
              invisible: true,
              sx: {
                top: 0,
              },
            },
          }}
        >
          <Map
            visible={viewVariant === ListingDisplayVariant.map}
            city={backgroundImageCity}
            markers={resultsToCoordinates(
              lastSearchQuery.geoPoints.value ?? lastSearchQuery.searchResults.value ?? [],
            )}
            handleMapToggle={handleMapToggleMap}
          />
        </Drawer>
      </div>
    </>
  );
}

export default Listing;
