import { useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { useRouter } from "next/router";
import { BaseFallbackPopup } from "@/src/component/base/BaseFallbackPopup";
import { ReachedLimitPopup } from "@/src/component/partial/ReachedLimitPopup";
import { VENDOR_MAX_GUEST_COUNT } from "@/src/const";
import {
  useInquiryData,
  useInquiryFormParams,
  useNextNavigation,
  useReachedLimitInquiry,
} from "@/src/hook";
import {
  ActivityLogic,
  AuthLogic,
  CMSLogic,
  SearchLogic,
  VendorLogic,
} from "@/src/model";
import { BlaceV2Type, ComponentType } from "@/src/type";
import { uniqueId } from "@/src/util";
import { DetailLayout } from "@src/component/layout";
import {
  CreateInquiryFooter,
  DetailSectionInfo,
  DetailSectionInfoBody,
  DetailSectionSimilarCarousel,
  ImageGrid,
  InquiryForm,
  InquiryFormCard,
  ShareDetails,
} from "@src/component/partial";
import styles from "./VendorDetail.module.scss";
import { VendorFeatures } from "./component";

interface VendorDetailProps {
  searchItem: BlaceV2Type.SearchType.Search;
  similarVendors: BlaceV2Type.SearchType.Search[];
}

function VendorDetail({ searchItem, similarVendors }: VendorDetailProps) {
  const nextNavigation = useNextNavigation();
  const [inquiryDrawerOpen, setInquiryDrawerOpen] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const inquiryFormData = useInquiryData();
  const inquiryFormParams = useInquiryFormParams({
    selectedVendor: searchItem,
  });
  const {
    isLimitReachedPopupVisible,
    handleDialogClose,
    setInquiryLimitReached,
  } = useReachedLimitInquiry();
  const router = useRouter();
  const isInPreviewMode = !!router?.query?.p; // preview mode during Listing Management
  const isInactivePopupOpen = !isInPreviewMode && !isAdmin && !SearchLogic.isSearchActive(searchItem);
  const vendorPricing = VendorLogic.formatVendorPricing(searchItem);
  const vendorContactName = VendorLogic.getContactName(searchItem);

  const navigateToDiscoverVendors = () => {
    nextNavigation.router.push(
      CMSLogic.constructLink(`/discover?dataType=vendor&searchId=${uniqueId()}`)
    );
  };

  /**
   * change the images to a usable type for the image grid
   */
  const images: ComponentType.Carousel.ImageType[] = useMemo(
    () => SearchLogic.formatCarouselImages(searchItem),
    [searchItem]
  );

  useEffect(() => {
    if (AuthLogic.hasAuthToken()) {
      const isAdmin = AuthLogic.getAuthCurrentUser()?.is_admin;

      setIsAdmin(!!isAdmin);
    }
  }, []);

  /**
   * Activity Tracking of this page
   */
  useEffect(() => {
    ActivityLogic.ga4Tracking("select_content", {});
    ActivityLogic.facebookTracking("track", "ViewContent", {});
    ActivityLogic.toActivityService({
      action: "vendor",
      actionId: searchItem.slug,
      actionIdType: "vendorSlug",
      locationInApp: "VendorDetail.ts",
    });
  }, [searchItem.slug]);

  return (
    <>
      <DetailLayout
        inquiryDrawerOpen={inquiryDrawerOpen}
        setInquiryDrawerClose={() => setInquiryDrawerOpen(false)}
        ImageGrid={
          <ImageGrid
            images={images}
            OverlayComponent={
              <ShareDetails
                name={searchItem.title}
                id={`vendor_v2_${searchItem.searchId}`}
              /> // or searchItem.data.id
            }
          />
        }
        LeftContainer={
          <>
            <Typography variant="h1">{searchItem.title}</Typography>
            <InquiryFormCard
              isExclusive={false}
              primaryContact={vendorContactName}
              pricing={vendorPricing}
              hideStartingAt={true}
              altStartingAt="Price Estimate"
              setInquiryDrawerOpen={() => setInquiryDrawerOpen(true)}
            />
            <div id="VendorInquiryCard" />
            <div className={styles.vendorAdjustSpacingMobile} />
            <DetailSectionInfo
              title=""
              Component={
                <DetailSectionInfoBody tag="h2">
                  {searchItem.description}
                </DetailSectionInfoBody>
              }
            />
            <VendorFeatures vendorFeatures={searchItem.attributes} />
          </>
        }
        RightContainer={
          <InquiryForm
            isExclusive={false}
            primaryContact={vendorContactName}
            pricing={vendorPricing}
            hideStartingAt={true}
            altStartingAt="Price Estimate"
            slug={searchItem.slug}
            altCatalogId={inquiryFormParams?.altCatalogId}
            catalogId={searchItem.catalogId}
            searchId={searchItem.searchId}
            inquiryType={BlaceV2Type.InquiryTypes.Vendor}
            maxGuestCount={VENDOR_MAX_GUEST_COUNT}
            inquiryFormData={inquiryFormData}
            setInquiryLimitReached={setInquiryLimitReached}
            listing={searchItem}
          />
        }
        BottomContainer={
          searchItem.regions?.length ? (
            <DetailSectionSimilarCarousel
              title="Similar Vendors"
              slug={searchItem.slug}
              similarSearchItems={similarVendors}
            />
          ) : <></>
        }
        FooterContainer={
          <CreateInquiryFooter
            explainPriceText={"Price Estimate"}
            pricing={vendorPricing ?? ""}
            setInquiryDrawerOpen={() => setInquiryDrawerOpen(true)}
          />
        }
        elementIdToTriggerFooter="VendorInquiryCard"
      />
      <ReachedLimitPopup
        isLimitReachedPopupVisible={isLimitReachedPopupVisible}
        handleDialogClose={handleDialogClose}
      />
      <BaseFallbackPopup
        isOpen={isInactivePopupOpen}
        title="Oops, this listing is no longer active"
        subTitle="Click below to discover more vendors on BLACE"
        ctaText="Search vendors"
        popupClasses={styles.inactiveFallbackPopup}
        handleCtaClick={navigateToDiscoverVendors}
      />
    </>
  );
}

export default VendorDetail;
